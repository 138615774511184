import { FC } from 'react'

import MenuList from '@root/components/menu/List'
import Layout from '@root/components/Layout'

const RegularMenu: FC = () => (
  <Layout>
    <MenuList />
  </Layout>
)

export default RegularMenu
